<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/clients">Clients</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/clients/new">New</a>
    </div>
    <div class="row issue-wrapper">
      <div class="col col-auto-5 issue-col p-4 bg-white">
        <div class="row px-2" v-if="formControl.profile">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Company Name
              <div class="float-right">
                <Toggle
                  v-model="clientForm.isErp"
                  class="toggle"
                >
                  <template v-slot:label="{ checked, classList }">
                    <span :class="classList.label">{{ checked ? 'ERP' : 'ERP' }}</span>
                  </template>
                </Toggle>
              </div>
            </h6>
            <input class="form-control" v-if="formControl.name && !clientForm.isErp" placeholder="Company name" v-model="clientForm.name"/>
            <span ref="erpSelect" v-if="formControl.name && clientForm.isErp">
              <ModelSelect
                v-if="formControl.name"
                v-model="clientForm.erpName"
                placeholder="Search ERP Client"
                :options="options.erpClients"
              >
              </ModelSelect>
            </span>
            <br>
            <h6 class="text-dark text-header">Client Code</h6>
            <input class="form-control" placeholder="Client code" v-model="clientForm.code" v-if="formControl.code"/>
            <br>
            <h6 class="text-dark text-header">Company logo</h6>
            <input class="form-control" placeholder="URL of company logo" v-model="clientForm.logo" v-if="formControl.logo"/>
            <br>
          </div>
          <div class="col-4">
            <img class="w-100" :src="validateLogo(clientForm.logo)">
          </div>
          <div class="col-8">
            <textarea class="form-control h-100" rows="7" placeholder="Write something about this client" v-model="clientForm.remarks" v-if="formControl.remarks"></textarea>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="createNewClient">
                <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Create client
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 issue-col issue-properties shadow-0 border-md-left p-0 m-0 element-disabled">
        <div class="row px-2 m-0 h-100 w-100">
          <div class="col-md-6 p-4 issue-col bg-white">
            <div class="mb-3 pb-4 border-bottom">
              <div class="property-dynamic">
                <h6 class="pt-2 text-dark text-header">Gitlab Repository</h6>
                <ModelSelect
                  placeholder="Search Gitlab repository"
                  :options="options.gitProjects"
                  :isDisabled="true"
                >
                </ModelSelect>
                <h6 class="pt-3 text-dark text-header">Business Unit</h6>
                <ModelSelect
                  placeholder="Select business unit"
                  :options="options.bunits"
                  :isDisabled="true"
                >
                </ModelSelect>
              </div>
              <div class="w-100 mt-4 text-right">
                <div class="btn btn-sm btn-secondary border-0 px-3 py-2 d-inline cursor-disabled bg-closed">
                  <i class="fas fa-plus"></i>&nbsp; Add project
                </div>
              </div>
            </div>
            <h6 class="text-dark text-header">Gitlab Projects</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Toggle from '@vueform/toggle/dist/toggle.vue2.js'
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect,
      Toggle
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        clients: [],
        formControl: {
          profile: true,
          name: true,
          isErp: true,
          code: true,
          logo: true,
          remarks: true
        },
        clientForm: {
          name: '',
          erpName: '',
          isErp: false,
          code: '',
          logo: '',
          remarks: ''
        },
        options: {
          erpClients: [],
          gitProjects: [],
          bunits: []
        },
        erpSearch: '',
        erpToken: ''
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      validateLogo: function (logo){
        return logo && logo.trim() !== '' ? logo : 'https://tools.iripple.com/uploads/-/system/appearance/logo/1/iRipple_logo.png'
      },
      createNewClient: async function (){
        let data = {
          name: this.clientForm.isErp ? this.clientForm.erpName.trim() : this.clientForm.name.trim(),
          isErp: this.clientForm.isErp,
          code: this.clientForm.code.trim().toUpperCase(),
          logo: this.clientForm.logo.trim(),
          remarks: this.clientForm.remarks.trim()
        }
        await this.getClients()
        let errors = this.validateClient(data)
        if(errors.length > 0)
          errors.forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Create client',
              type: 'warn',
              text: error
            })
          });
        else
          this.submitNewClient(data)
      },
      submitNewClient: async function(data){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/clients/new`,
              {client: data},
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          let client = response.data.client;
          if(client && client._id){
            this.$alertify({
              group: 'notification',
              title: `Create client`,
              type: 'success',
              text: `${client.code} was successfully added.`
            })
            this.$router.push(`/clients/${client.code}`)
          }
          else{
            this.$alertify({
              group: 'notification',
              title: 'Create failed',
              type: 'warn',
              text: 'We encountered a problem while saving this client. Please try again.'
            })
          }
          this.loader(false)
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
      },
      validateClient: function(data) {
        let errors = [];
        const normalizedCode = data.code ? data.code.trim().toLowerCase() : '';
        const normalizedName = data.name ? data.name.trim().toLowerCase() : '';
        if (data.name === '') {
          errors.push('Client name cannot be empty');
        }
        if (data.code === '') {
          errors.push('Client code cannot be empty');
        }
        if (normalizedCode === 'new') {
          errors.push("Invalid code. The word 'new' is reserved");
        }
        if (normalizedCode.length !== 4) {
          errors.push('Client code must have exactly four characters');
        }
        const existingCodes = new Set(this.clients.filter(r => r.code).map(r => r.code.toLowerCase()));
        if (existingCodes.has(normalizedCode)) {
          errors.push('Client code already exists, try another one');
        }
        const existingNames = new Set(this.clients.filter(r => r.name).map(r => r.name.toLowerCase()));
        if (existingNames.has(normalizedName)) {
          errors.push('Client name already exists, try another one');
        }
        return errors;
      },
      async getERPClients(){
        await this.getERPToken()
        try {
          const formData = new FormData()
          formData.append('target', 'client_name')
          formData.append('value', this.erpSearch)
          const response = await this.$openHttp.post(
            `${this.$erpEndpoint}/masterdata/customer/get_client_dtls`, formData,
            {
              headers: {
                'Authorization': `Bearer ${this.erpToken}`,
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          );
          this.options.erpClients = response.data.map(r => {
            return { key: r.client_id, text: r.client_name, value: r.client_name, record: r }
          })
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.clients = response.data.clients
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      validateUser: async function(){
       if(!['Admin', 'Manager'].includes(this.user.role)){
         this.$alertify({
           group: 'notification',
           title: `Access denied`,
           type: 'warning',
           text: `You don't have access to this module.`
         })
         await this.$sleep(500);
         window.history.back()
       }
      }
    },
    mounted: function(){
      this.validateUser()
      this.getERPToken()
      this.$watch('clientForm.isErp', () => {
        if(this.$refs.erpSelect){
          this.$refs.erpSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("keyup", (val) => {
            this.erpSearch = val.target.value
            this.getERPClients()
          })
          this.$refs.erpSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("blur", (val) => {
            this.erpSearch = val.target.value
            if(!this.clientForm.erpName)
              this.getERPClients()
          })
        }
      });
    }
  }
</script>
